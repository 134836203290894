import React from "react";
import Profile from "./profile";

const index = () => {
  return (
    <div className="main-container">
      <Profile />
    </div>
  );
};

export default index;
